import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { Suspense, useEffect, useMemo, useState } from 'react';
import { createRoot } from 'react-dom/client';
import { HelmetProvider } from 'react-helmet-async';
import { I18nextProvider, useTranslation } from 'react-i18next';
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from 'react-router-dom';

import '@fontsource/inter/300.css';
import '@fontsource/inter/400.css';
import '@fontsource/inter/500.css';
import '@fontsource/inter/600.css';
import '@fontsource/inter/700.css';
import '@fontsource/montserrat-alternates/400.css';
import '@fontsource/montserrat-alternates/500.css';
import '@fontsource/montserrat-alternates/600.css';

import './global.module.css';

import Footer from './blocks/footer';
import Navbar from './blocks/navbar';
import { Banner } from './components/Banner';
import { CookiesAlert } from './components/CookiesAlert';
import { Loader } from './components/Loader';
import { langs } from './config/langs';
import { useCookiesAcceptance } from './hooks/useCookiesAcceptance';
import i18nConfig from './i18n';
import { getBanners } from './models/Banners';
import Contacts from './pages/contacts';
import CurrentNews from './pages/currentNews';
import HealthPage from './pages/health';
import Home from './pages/home';
import Map from './pages/map';
import News from './pages/news';
import NotFound from './pages/notFound';
import Partners from './pages/partners';

import './styles/index.scss';
import './styles/global/global.scss';

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: 'https://7fcdb39f3f794c7ba5a04d1d05894241@sentry.131.ru/5',
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 1.0,
  });
}

const App = (props) => {
  const { location, match } = props;
  const { i18n } = useTranslation();

  const [banner, setBanner] = useState({});
  const [isBannerShown, setIsBannerShown] = useState(false);

  const { accept: acceptCookies, isAccepted: isAcceptedCookies } =
    useCookiesAcceptance();

  useEffect(() => {
    const load = async () => {
      const showBanner = () => sessionStorage.getItem('showBanner');
      const { currentBanner, ...banners } = await getBanners({
        lang: i18n.language,
      });

      setBanner(() => ({ ...banners[currentBanner] }));

      if (!currentBanner) {
        return;
      }

      if (!showBanner()) {
        sessionStorage.setItem('showBanner', true);
      }

      setIsBannerShown(JSON.parse(showBanner()));
    };

    load();
  }, [i18n.language]);

  useEffect(() => {
    if (!location.hash) {
      window.scrollTo(0, 0);
    }
  }, [location.hash, location.pathname]);

  const handleCloseBanner = () => {
    sessionStorage.setItem('showBanner', false);
    setIsBannerShown(false);
  };

  const renderedRoutes = useMemo(() => {
    const isSupportedLang = langs.some(
      (lang) => lang.code === match.params.locale,
    );

    if (isSupportedLang) {
      return (
        <Switch>
          <Route exact path={`${match.url}/`} component={Home} />

          <Route path={`${match.url}/company/contacts`} component={Contacts} />

          <Route path={`${match.url}/company/partners`} component={Partners} />

          <Route exact path={`${match.url}/news`} component={News} />

          <Route
            path={`${match.url}/news/:year/:alias`}
            component={CurrentNews}
          />

          <Route
            path={`${match.url}/transfer/find-locations`}
            component={Map}
          />

          <Route path="*" component={NotFound} />
        </Switch>
      );
    }

    return (
      <Switch>
        <Route path="*" component={NotFound} />
      </Switch>
    );
  }, [match.params.locale, match.url]);

  return (
    <HelmetProvider>
      <div className="AppContent">
        {isBannerShown ? (
          <Banner onClose={handleCloseBanner} banner={banner} />
        ) : null}

        <CookiesAlert isShown={!isAcceptedCookies} onAccept={acceptCookies} />

        <Navbar match={match} />

        {renderedRoutes}
      </div>
      <Footer match={match} />
    </HelmetProvider>
  );
};

const FrontendDengiru = () => {
  return (
    <Suspense fallback={<Loader />}>
      <I18nextProvider i18n={i18nConfig}>
        <Router>
          <Switch>
            <Route path="/:locale" component={App} />

            <Route path="/health" component={HealthPage} />

            <Route path="*" render={() => <Redirect to="/ru" />} />
          </Switch>
        </Router>
      </I18nextProvider>
    </Suspense>
  );
};

createRoot(document.getElementById('root')).render(<FrontendDengiru />);

import { useTranslation } from 'react-i18next';

import BlockBorder from '../blocks/blockBorder';
import BlockToMap from '../blocks/blockToMap';
import TopBanner from '../blocks/topBanner';
import SEO from '../components/seo';

const Contacts = () => {
  const { t, i18n } = useTranslation(['contacts_page']);

  return (
    <>
      <SEO seo={t('seo')} locale={i18n.language} />

      <TopBanner banner={t('topBannerData')} />

      <BlockBorder
        content={t('contactInfo')}
        additionalBlockStyle="BorderBlock-BottomMargin"
      />

      <BlockBorder
        content={t('contactQuestions')}
        additionalBlockStyle="BorderBlock-BottomMargin"
      />

      <BlockBorder content={t('marketingQuestions')} />

      <BlockToMap content={t('blockToMap')} />
    </>
  );
};

export default Contacts;

import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { MobileApp } from '../blocks/MobileApp';
import CountryBlock from '../blocks/countryBlock';
import FAQ from '../blocks/faq';
import Features3Rows from '../blocks/features3rows';
import LatestNews from '../blocks/latestNews';
import Slider from '../blocks/slider';
import SwitchContent from '../blocks/switchContent';
import SEO from '../components/seo';
import { getRelatedList } from '../models/News';

const Home = () => {
  const { t, i18n } = useTranslation(['home_page']);

  const [latestNews, setLatestNews] = useState([]);

  useEffect(() => {
    const load = async () => {
      const { data } = await getRelatedList(
        {
          lang: i18n.language,
        },
        { maxCount: 3 },
      );
      if (data?.length) {
        setLatestNews(data);
      }
    };
    load();
  }, [i18n.language, setLatestNews, t]);

  return (
    <>
      <SEO isHomePage seo={t('seo')} locale={i18n.language} />

      <Slider slides={t('slider.slides')} />

      <CountryBlock countries={t('countries')} />

      <MobileApp mobileAppContent={t('mobile-app')} />

      <SwitchContent data={t('send-and-receive')} />

      <Features3Rows data={t('features')} />

      <LatestNews
        data={latestNews}
        language={i18n.language}
        locale={t('news')}
      />

      <FAQ faqContent={t('faq')} />
    </>
  );
};

export default Home;

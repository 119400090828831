import CustomListSubItem from '../components/customListSubItem';
import '../styles/components/customList.scss';

const CustomListItem = ({ item, index, style }) => {
  return (
    <li
      key={index}
      className={`CustomNumberedList CustomNumberedList-${index + 1} ${
        item.style ? 'CustomNumberedList-' + item.style : ''
      }  ${style ? 'CustomNumberedList-' + style : ''}`}
    >
      <div
        className={`CustomNumberedList-Sublist-Wrapper ${
          item.style ? 'CustomNumberedList-Sublist-Wrapper-' + item.style : ''
        } ${style ? 'CustomNumberedList-Sublist-Wrapper-' + style : ''}`}
      >
        <span
          className="CustomNumberedList-Sublist-Wrapper-Item"
          dangerouslySetInnerHTML={{ __html: item.item }}
        />
        {item.sublist && (
          <ul className="CustomNumberedList-Sublist">
            {item.sublist.map((itemSublist, indexSublist) => (
              <CustomListSubItem key={indexSublist} item={itemSublist.item} />
            ))}
          </ul>
        )}
      </div>
    </li>
  );
};

export default CustomListItem;
